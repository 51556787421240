/*Monitoring Screen Start*/
.section-monitoring {
  .monitoring-table {
    table {
      width: 100%;
      border-collapse: separate;
      border-radius: 1em;
      overflow: hidden;
      border-spacing: 0 15px;
      vertical-align: top;
      align-items: start;
    }

    tr {
      padding: 1.5em;
      background: #1c2253;
      border-radius: 1em 0 0 1em;
    }

    thead {
      tr {
        background: #384378;
        border-radius: 1em 0 0 1em;
      }

      td {
        font-size: 16px;
        font-family: Rubik-medium;
        padding: 1.5em;
      }
    }

    tbody {
      td {
        vertical-align: top;
        align-items: start;
        padding: 15px 1.5em;
      }

      td:nth-child(2) {
        overflow-x: hidden;

        li {
          overflow: hidden;
          max-width: 250px;
          text-overflow: ellipsis;
        }
      }
    }

    td {
      font-size: 14px;
      white-space: nowrap;
      border: transparent;
    }

    td:first-child {
      border-radius: 1em 0 0 1em;
    }

    td:last-child {
      border-radius: 0 1em 1em 0;
    }


  }

  .monitoring-table::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  .monitoring-table::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  .monitoring-table::-webkit-scrollbar-thumb {
    background: #384378;
    border-radius: 10px;
  }

  .add-ele-btn {
    padding: 12px 35px;
    border-radius: 40px;
    outline: none;
  }
}

/*Monitoring Screen End*/

/*Monitoring Edit Screen Address Start*/
.section-monitoring-address {
  .address-tabination {
    border-radius: 10px;

    .score-select {
      color: #fff;
      .css-1bl8mcx-Control, .css-1domaf0 {
        padding: 10px 30px;
        display: flex;
        background: #fd9620;
        border-radius: 40px;
        @media screen and (width: 768px){
          width: 150px;
        }
      }

      .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
        color: #fff;

        &:hover {
          color: #fff;
        }
      }

      .css-1okebmr-indicatorSeparator {
        background-color: #fff;
      }
    }

    .address-content {
      .email-alert{
        background: #4d84e8;
        border-radius: 40px;
        border: none;
        outline: none;
        padding: 10px 30px;
      }
      .address-tab {
        border: 2px solid #fd9620;
        border-radius: 40px;
        width: fit-content;

        li {
          a {
            padding: 8px 40px;
            @media screen and (max-width: 576px) {
              padding: 8px 19px;
            }
          }

          .active {
            border-radius: 20px;
            background-color: #fd9620;
            padding: 8px 40px;
            outline: none;
            @media screen and (max-width: 576px) {
              padding: 8px 19px;
            }
          }
        }
      }
      .address-right {
        .global-scoring-btn {
          background: transparent;
          border: 1px solid #ffffff;
          color: #ffffff;
          padding: 8px 20px;
          outline: none;
          border-radius: 20px;
        }

        .active {
          background: #4d84e8;
          border: 1px solid #4d84e8;
        }
      }
      .address-left{
        li{
          word-break: break-all;
        }
      }
    }

    .alert-body {
      .ask-us-btn {
        padding: 10px 40px;
        border-radius: 40px;
        border: none;
        outline: none;
      }
      li{
        margin-bottom: 20px;
      }
      input{
        @media screen and (min-width: 768px) and (max-width: 991px){
          width: 20%;
        }
      }
    }



  }

  .average-global-table {
    .scor-btn-coll {
      background: #fd9620;
      border: 1px solid #fd9620;
      padding: 10px 30px;
      border-radius: 40px;
      white-space: nowrap;
      outline: none;
    }

    table {
      width: 100%;
      border-collapse: separate;
      border-radius: 1em;
      overflow: hidden;
      border-spacing: 0 15px;
    }

    tr {
      padding: 1em;
      background: #1c2253;
    }

    thead {
      tr {
        background: #384378;
      }
    }


    td {
      vertical-align: middle;
      padding: .7em;
      border: transparent;
      white-space: nowrap;
    }

    td:first-child {
      border-radius: 10px 0 0 10px;
    }

    td:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
}

.monitoring-add-user {
  .input {
    width: 100%;
    height: 45px;
    padding: 0 1rem;
    margin-top: 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: 2px solid #d4d5d6;
    color: #565656;
    -webkit-appearance: none;
  }

  .input:focus {
    border-color: cornflowerblue;
    outline: none;
  }

  .input.has-error {
    border-color: tomato;
  }

  .error {
    margin: 0;
    font-size: 90%;
    color: tomato;
  }

  .tag-item {
    background-color: #d4d5d6;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
  }

  .tag-item > .button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// style for comment Tab start
.post-btn{
  padding: 10px 40px;
  margin-bottom: 10px;
  border-radius: 40px;
  border: none;
  color: #ffffff;
  outline: none !important;
}

.comment-box{
  textarea::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  textarea::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  /* Handle */
  textarea::-webkit-scrollbar-thumb {
    background: #fd9620;
    border-radius: 10px;
  }
 }

.comment-border{
 border-bottom: 1px solid #333a63;
}

// style for comment tab end

/*Monitoring Edit Screen Address End*/


