/*Style For search Coin component*/

.section-banner {
  .banner-content {
    .search-bar {
      padding: 15px 25px;
      border-radius: 40px;
      height: 60px;
      text-overflow: ellipsis;
    }

    p {
      font-size: 18px;
    }

    .banner-tabs {
      li.disabled {
        pointer-events: none;
        cursor: none;
        opacity: 0.5;
      }

      li {
        display: inline-block;
        margin: 10px;

        button {
          display: inline-block;
          padding: 15px 30px;
          border-radius: 39px;
          border: 1px solid #fff;
          background-color: #fff;
          outline: none;
          box-shadow: none;
        }

        .active {
          border: 1px solid #2367c8;
          background-color: #2367c8;
        }
      }
    }

    input {
      background-image: url("../img/searchCoin/search.svg");
      background-position: 97%;
      background-repeat: no-repeat;
      background-size: 18px;
      -webkit-appearance: none;
    }

    input::placeholder {
      text-overflow: ellipsis;
      color: rgba(46, 48, 51, 0.4);
    }

    .get-scorling-btn {
      button {
        display: inline-block;
        background-color: #fd9620;
        padding: 15px 50px;
        border-radius: 40px;
        font-size: 22px;
        font-weight: 500;
        color: #ffffff;
        border: 1px solid #fd9620;
        outline: none;
      }
    }
  }

  width: 100%;
}

.options {
  display: block;
  list-style: none;
  width: 98%;
  transition: width 0.3s;
  margin: 10px auto;
  padding: 10px;
  position: absolute;
  background: #FFFFFF;
  border-radius: 20px;
  max-height: 500px;
  overflow-y: scroll;

  li {
    display: block;
    background: white;
    margin: 10px auto;
    padding: 10px;
    font-size: 1.2rem;
    width: 100%;
    border-radius: 2px;
    text-align: left;
    word-break: break-all;

    &:hover {
      font-weight: bold;
      color: #00b4cc;
      cursor: pointer;
      transition: 0.3s all;
    }
  }

  li.option-active {
    background: whitesmoke;
    font-size: 1.5rem;
    color: #00b4cc;
  }
}

/*Get Score Popup Start*/

.get-credit-modal {
  .rad-btn {
    display: block;
    position: relative;
    padding-left: 40px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked~.checkmark {
        background-color: #1c2253;

        &:after {
          display: block;
        }
      }
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #1c2253;
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #fd9620;
      }
    }
  }

  .credit-card-modal {
    input {
      outline: none;
      width: 100%;
      padding: 15px !important;
      margin-top: 15px;
    }
  }
}

.payment {
  input {
    width: 100% !important;
    padding: 15px !important;
    outline: none;
  }

  img {
    margin-bottom: 10px !important;
  }
}

/*Copy Icon Css Start*/
.copy-image {
  position: absolute;
  right: -20px;
  bottom: 10px;
}

/*Copy Icon Css End*/