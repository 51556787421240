.section-signup {
  padding: 15px;
  width: 100%;

  .signup-main {
    background-color: #f4f4f4;
    border-radius: 6px;
    border: 4px solid #ff8a00;
    color: #000;
    box-shadow: 0 0 10px #d4d4d4;
    font-family: "Lato";
    a{
      color: inherit;
    }
    .signup-tabs {
      button {
        background: #39407c;
        border: 2px solid #ffffff;
        border-radius: 6px;
        width: 100%;
        outline: none;
      }

      .active {
        border: 2px solid #fd9620;
        background-color: #fd9620;
      }
    }

    input {
      background: #fff;
      border-radius: 6px;
      color: inherit;
      border: none;
      padding: 30px 15px;

      &:focus {
        background: #fff;
        box-shadow: none;
        border: none;
      }
    }

    .select-box {

      background-color: #1c2253;
      border-radius: 6px;
      padding: 11px 0;

      select {
        background-image: url("../img/coinDetails/down.svg");
        background-repeat: no-repeat;
        background-color: #1c2253;
        color: #ffffff;
        border: none;
        box-shadow: none;
        background-size: 35px;
      }
    }


    input::placeholder {
      color: #ffffff;
      font-size: 14px;
    }

    .signup-btn {
      padding: 12px 50px;
      border-radius: 6px;
      box-shadow: none;
      color: inherit;
      background-color: $primary-orange;
    }

    p {
      a {
        color: inherit;
        border-bottom: 1px solid;

        &:hover {
          color: #fd9620;
        }
      }
    }
  }
}

.thanks-main {
  background-color: #39407c;
  border-radius: 40px;
}

/*Social Button*/

.loginBtn {
  box-sizing: border-box;
  position: relative;
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}

.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}

.loginBtn:focus {
  outline: none;
}

.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  text-shadow: 0 -1px 0 #354C8C;
}

.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}

.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}

/* Google */
.loginBtn--google {
  background: #DD4B39;
}

.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}

.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}

.usetext {

  &:before,
  &:after {
    display: inline-block;
    content: "";
    width: calc(50% - 1.9em);
    height: 0px;
    border-bottom: 1px solid rgb(204, 204, 204);
    vertical-align: middle;
    margin: 0 2px;
  }
}
