.successful-main {
  background-color: #39407c;
  border-radius: 6px;

  button {
    outline: none;
    border-radius: 6px;
    border: none;
    padding: 12px 50px;
  }
}
