/*Style for coin details*/
.section-coin-details {
  width: 100%;

  .coin-details-wraper {
    padding: 6rem;
  }

  .tabination {
    .sub-menu-nav {
      justify-content: space-between;
      font-size: 19px;

      &:focus {
        outline: none;
      }

      li {
        button {
          &:focus {
            outline: none;
          }

          border: none;
          border-top: 8px solid transparent;
          background-color: unset;

          &.active {
            border-top-color: #ff8000 !important;
          }

          &.inactive {
            pointer-events: none;
            color: #b1aeac;
          }
        }
      }
    }

    /*Scoring Tab Start*/
    .scoring-collapse {
      .card {
        // background-color: #1c2253;
        border-radius: 0px;
        box-shadow: 0px 5px 5px #dadada8c;
      }

      .card-header {
        // height: 80px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        border-radius: 0;
        border-bottom: none;

        .scor-btn-coll {
          font-family: Rubik-medium;
          background: #fd9620;
          border: 1px solid #fd9620;
          padding: 5px 20px;
          border-radius: 40px;
          white-space: nowrap;
          outline: none;
        }
      }

      .card-body {
        background-color: #171d4f;
        border-top: 1px solid #333a63;

        li {
          font-size: 14px;
          color: #ff5023;
          margin-left: 0;
          margin-bottom: 10px;
        }

        .identify-btns {
          background-color: #1c2253;
          border-top: 1px solid #333a63;

          .dwn-report {
            padding: 10px 30px;
            border-radius: 20px;
            background: #fd9620;
            border: none;
            outline: none;
          }

          .reg-hash {
            padding: 10px 30px;
            border-radius: 20px;
            background: #2367c8;
            border: none;
            outline: none;
          }
        }
      }
    }

    /*Scoring Tab End*/

    /*Summary Tab Start*/
    .summary-main {
      .card {
        border-radius: 10px;

        h5 {
          font-family: Rubik-medium;
        }

        .law-btn {
          border: 1px solid #3cc24c;
          background-color: #3cc24c;
          padding: 8px 30px;
          border-radius: 40px;
          display: inline-block;
        }
      }

      .summary-left {
        li {
          margin-left: 0;
          margin-right: 0;
          white-space: nowrap;
        }
      }
    }

    /*Summary Tab End*/

    /*Summary_V2 Tab Start*/
    .summary-main-v2 {
      font-family: Lato;

      .wallet-title {
        text-decoration: underline;
        color: #000000;
        font-size: 23px;
        margin-bottom: 28px;
      }

      .pb-12 {
        padding-bottom: 12px;
      }

      .pl-21 {
        padding-left: 21px !important;
      }

      .mb-30 {
        margin-bottom: 30px;
      }

      .mb-76 {
        margin-bottom: 76px;
      }

      .pd-20 {
        padding-bottom: 20px;
      }

      .label {
        font-weight: bold;
        color: #484848;
      }

      .additional-info {
        text-decoration: underline;
        color: #000000;
        font-size: 23px;
        margin-bottom: 23px;
      }

      .block-chain-overview {
        text-decoration: underline;
        font-weight: bold;
        color: #484848;
        margin-bottom: 37px;
      }

      .brs-container {
        padding: 13px 0px 0px 21px;

        .coin-value-container {
          height: 150px;
          align-items: center;
          padding: 0px;
          display: flex;
        }
      }

      .brs-title {
        text-decoration: underline;
        color: #000000;
        font-size: 23px;
      }

      //overflow: hidden;
      //width: 100%;
      //text-overflow: ellipsis;
      //display: block;

      .brs-value {
        font-size: 60px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 1600px) and (min-width: 1200px) {
          font-size: 50px;
          vertical-align: middle;
          text-align: right;
          padding: 0 0 0 15px;
          line-height: 90px;
        }
      }

      .button-group {
        display: flex;
        justify-content: space-around;
      }

      .normal-button {
        background-color: #f4f4f4;
        width: 88px;
        height: 27px;
        font-size: 22px;
        border-radius: 8px;
        border: 1px solid;
      }

      .active-button {
        background-color: #ff8a00;
        color: #fff;
        width: 88px;
        height: 27px;
        font-size: 22px;
        border-radius: 8px;
      }

      .active-button:focus {
        outline: none;
      }

      .received-color {
        color: #5ae5b3 !important;
      }

      .sent-color {
        color: #ff5242 !important;
      }

      .summary-paper {
        padding: 13px 16px 0px 33px;
        text-align: left;
        color: #484848;
        margin-bottom: 36px;
        box-shadow: 0px 5px 5px #dadada8c;

      }
    }

    /*Summary_V2 Tab End*/

    /*Balance Tab Start*/
    .balance-main {
      .card {
        border-radius: 10px;
        border: none;
        box-shadow: 0 0 10px #e8e8e8;

        h5 {
          font-family: Rubik-medium;
        }

        &.card-secondary {
          background-color: #fff;
          border-radius: 0;

          .card-header {
            background-color: #fff;
            border: none;

            h5 {
              font-size: 19px;
              color: #000;
              font-weight: 400;
            }
          }

          .card-content {
            display: flex;
            padding: 30px 0;
          }
        }
      }
    }

    /*Balance Tab End*/

    /*Address Tab Start*/
    .address-main {
      .hop-select {
        color: #fff;

        .css-1bl8mcx-Control,
        .css-1domaf0 {
          padding: 10px 30px;
          display: flex;
          background: #fd9620;
          border-radius: 40px;
        }

        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
          color: #fff;

          &:hover {
            color: #fff;
          }
        }

        .css-1okebmr-indicatorSeparator {
          background-color: #fff;
        }
      }

      .address-table {
        ::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #384378;
          border-radius: 10px;
        }

        .scor-btn-coll {
          background: #fd9620;
          border: 1px solid #fd9620;
          padding: 4px 18px;
          border-radius: 40px;
          white-space: nowrap;
          outline: none;
        }

        .scor-btn-coll-disable {
          background: #fd9620;
          border: 1px solid #fd9620;
          padding: 4px 18px;
          border-radius: 40px;
          white-space: nowrap;
          outline: none;
          cursor: not-allowed;
        }

        table {
          width: 100%;
          border-collapse: separate;
          border-radius: 1em;
          overflow: hidden;
          border-spacing: 0 15px;
        }

        tr {
          padding: 1em;
          background: #1c2253;
        }

        thead {
          tr {
            background: #384378;
          }
        }

        td {
          vertical-align: middle;
          padding: 0.7em;
          border: transparent;
          white-space: nowrap;
          min-width: 180px;
        }

        td:first-child {
          border-radius: 10px 0 0 10px;
        }

        td:last-child {
          border-radius: 0 10px 10px 0;
        }

        tbody {
          td {
            text-align: center;
          }
        }
      }

      .add-ele-btn {
        padding: 12px 35px;
        border-radius: 40px;
        outline: none;
        border: none;
      }
    }

    /*Address Tab End*/

    /*Web-Analysis Tab Start*/
    .web-analysis-table {
      table {
        width: 100%;
        border-collapse: separate;
        border-radius: 1em;
        overflow: hidden;
        border-spacing: 0 15px;
      }

      tr {
        padding: 1.5em;
        background: #1c2253;
        border-radius: 1em 0 0 1em;
      }

      thead {
        tr {
          background: #384378;
          border-radius: 1em 0 0 1em;

          td {
            font-family: Rubik-medium;
            font-size: 16px;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 14px;
          }
        }
      }

      td {
        vertical-align: middle;
        padding: 1.5em;
        border: transparent;
      }

      td:first-child {
        border-radius: 1em 0 0 1em;
      }

      td:last-child {
        border-radius: 0 1em 1em 0;
      }
    }

    /*Web-Analysis Tab End*/

    /*Transaction tab start*/
    .transaction {
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
      padding: 0 20px;

      svg {
        width: 100% !important;
        height: 100vh !important;
      }
    }

    /*Transaction tab end*/
  }
}

.arrow-img {
  width: 40px;
}

.json-viewer-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper-modal {
  background-color: white;
  border: 2px solid #000;
  height: calc(100vh - 300px);
  overflow-y: scroll;
  padding: 20px 50px;
  width: calc(100% - 100px);
}

.iframe-big-device {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding: 0 20px;
  height: 100vh;
  border: none;
}

.credit-table {
  max-height: 500px;
  overflow-y: scroll;

  table {
    width: 100%;
    border-collapse: separate;
    border-radius: 1em;
    overflow: hidden;
    border-spacing: 0 15px;
  }

  tr {
    padding: 1.5em;
    background: #1c2253;
    border-radius: 1em 0 0 1em;
  }

  thead {
    tr {
      background: #1c2253;
      border-radius: 1em 0 0 1em;

      td {
        font-family: Rubik-medium;
        font-size: 16px;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 14px;
      }
    }
  }

  td {
    vertical-align: middle;
    padding: 1.5em;
    border: transparent;
  }

  td:first-child {
    border-radius: 1em 0 0 1em;
  }

  td:last-child {
    border-radius: 0 1em 1em 0;
  }
}

.credit-table::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.credit-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.credit-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

/*Coin Template CSS Start*/
.coinTemplate {
  background: #ffffff;

  h6 {
    margin: 0;
    color: #000000;
  }

  p {
    margin: 0;
    font-size: 12px;
    color: #000000;
  }

  li {
    display: inline-block;
    margin: 0 50px;
  }

  .templateKey {
    background-color: #fd9620;
    border-radius: 40px;
    color: #ffffff;
  }
}

.card.account-report {
  box-shadow: 0 0 15px #d9d9d9;
  padding: 15px 25px;
  position: relative;
  background-color: #fff;
  // min-height: 350px;
  margin-bottom: 35px;
  font-family: "Lato";

  @media screen and (max-width: 1199px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .card-header {
    h3 {
      text-transform: capitalize;
      font-size: 23px;
      text-decoration: underline;
      margin: 0;
      color: #484848;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }

    .card-header__branch {}

    .card-header__controls {
      display: flex;
      align-items: center;
      color: map-get($color, text);
      min-height: 51px;

      span[class^="text"] {
        margin: 0 15px;
      }

      >p {
        margin: 0;
      }
    }
  }

  .account-report__panels {
    position: absolute;
    right: 15px;
    bottom: 15px;

    @media screen and (max-width: 1199px) {
      position: static;
      right: auto;
      bottom: auto;
      margin: 15px 0 0px;
    }
  }

  .account-report__main {
    margin: 15px 0 0;
  }
}

.account-report__main {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1199px) {
    flex-wrap: wrap;
  }

  .account-report__sumary {
    text-align: center;
    width: 15%;
    padding-top: 20px;

    @media screen and (max-width: 1199px) {
      width: 100%;
      margin: 0 0 15px;
    }

    strong {
      font-size: 112px;
      font-weight: 300;
      color: #484848;
      line-height: normal;

      @media screen and (max-width: 767px) {
        font-size: 60px;
      }
    }

    .account-report__sumary__flags {
      margin: 20px 0 0;
      font-size: 19px;
      color: #484848;

      span {
        display: inline-block;
        vertical-align: bottom;
      }

      img {
        display: inline-block;
        vertical-align: bottom;
      }
    }
  }

  .account-report__reports-list {
    width: 65%;
    color: #333;
    column-count: 3;
    text-align: center;

    @media screen and (max-width: 1199px) {
      column-count: 2;
      width: 100%;
      margin: 0px;
    }

    @media screen and (max-width: 767px) {
      column-count: 1;
    }
  }

  .account-report__explain {
    width: 15%;
    color: #484848;
    text-align: right;

    @media screen and (max-width: 1199px) {
      width: 100%;
    }

    a {
      text-decoration: none;
      font-size: 16px;
      color: #484848;

      img {
        display: inline-block;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
  }
}

.account-report__report-item {
  min-height: 36px;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 7px;
  border: none;
  text-transform: capitalize;
  text-align: center;
  padding: 0 30px;
  color: #fff;
  width: 90%;
  display: inline-block;
  margin: 0 auto 30px;
  cursor: pointer;
  transition: all ease 0.8s;
  padding: 5px 0;

  @media screen and (max-width: 767px) {
    margin: 0 0 10px;
    width: 100%;
  }

  &:hover {
    opacity: 0.7;
  }

  &.account-report__report-item__typeA {
    background-color: #5ae5b3;
  }

  &.account-report__report-item__typeB {
    background-color: #ff8a00;
  }

  &.account-report__report-item__typeC {
    background-color: #ff5242;
  }

  span {
    font-size: 19px;
  }

  strong {
    position: absolute;
    right: 1px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 22px;
    font-weight: 700;
    background-color: inherit;
    padding: 0 10px 0 5px;
  }
}

.btn {
  border-radius: 7px;
  border: none;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 22px;
  padding: 0 23px;
  background-color: transparent;
  position: relative;
  text-decoration: none;
  transition: all ease 0.8s;

  &:hover {
    opacity: 0.7;
    color: #d9d9d9;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }

  &.btn-align {
    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &.btn-monitor {
    background-color: #4794fd;
  }

  &.btn-report {
    border: 1px solid #484848;
    color: #484848;
    min-width: 225px;
  }

  &.btn-panels {
    min-height: 51px;
    font-size: 19px;
    color: #fff;
    margin-bottom: 10px;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: normal;
      min-height: 40px;
    }
  }

  &.btn-scoring {
    background-color: #4794fd;
    font-size: 19px;
    text-transform: capitalize;
    height: 51px;
    line-height: 51px;
  }

  &.btn-download-report {
    background-color: #ffc000;
    width: 100%;
  }

  &.btn-download-export {
    background-color: #4794fd;
    width: 100%;
  }
}
.card__main-content__control{
  display: flex;
  justify-content: flex-end;
  .btn-switch {
    font-size: 17px;
    color: #484848;
    height: 21px;
    border-radius: 10px;
    border: 1px solid #484848;
    margin: 0 16px;
    min-height: unset;
    outline: none;

    &:hover,
    &.btn-switch--active {
      color: #fff;
      background-color: #ff8a00;
    }
  }
}

.card-chart--donus {
  text-align: center;

  .card-body__chart__switch {
    display: flex;
    justify-content: center;
    padding: 0 0 23px;

    .btn-switch {
      font-size: 17px;
      color: #484848;
      height: 21px;
      border-radius: 10px;
      border: 1px solid #484848;
      margin: 0 16px;
      min-height: unset;
      outline: none;

      &:hover,
      &.btn-switch--active {
        color: #fff;
        background-color: #ff8a00;
      }
    }
  }

  .card-body__chart__paragrap {
    font-size: 27px;
    color: map-get($color, text);
    max-width: 300px;
    margin: 20px auto 0;
  }

  .card-body {
    position: relative;
    margin-top: 3rem;
    padding: 0;

    canvas {
      height: 307px;
    }

    #chartjs-tooltip {
      z-index: 9;
      color: #333;
      font-size: 47px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      word-spacing: 5px;
      pointer-events: none;
    }
  }
}

.address-container {
  background-color: #f4f4f4;
  font-family: "Lato", sans-serif;
  color: #000;

  .scroll-bar-addr {
    width: 100%;
    margin: 20px 0 35px 0;
    font-size: 1rem;

    .bar {
      max-width: 634px;

      .MuiSlider-thumb {
        width: 25px;
        height: 25px;
        margin-top: -10px;
        color: #4794FD;
        &:hover {
          box-shadow: none;
        }
      }

      .MuiSlider-rail {
        height: 5px;
        color: #707070;
        opacity: 1;
      }

      .MuiSlider-track {
        height: 5px;
        color: #707070;
      }
    }
  }

  .hop-selects {
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color: #ffffff;
    align-items: center;
    height: 4rem;
    margin-bottom: 2rem;

    .hop-select-text {
      font-size: 1.4rem;
      cursor: pointer;
    }

    .hops {
      border: none;
      border-bottom: 8px solid transparent;
    }

    .hops-selected {
      border-bottom-color: #ff8000 !important;
    }
  }

  @media screen and (min-width: 1024px) {
    .address-content {
      grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
  }

  .address-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(autofit, minmax(100px, 1fr));
    grid-gap: 2.5rem;

    .card-address {
      padding: 1.5rem;
      box-shadow: 0 10px 20px rgba(112, 112, 112, 0.19), 0 6px 6px rgba(112, 112, 112, 0.23);
      border: none;

      .card-title {
        font-size: 1.3rem;
        margin: 0;
      }

      .card-time {
        color: #484848;
      }

      .card-content {
        .address {
          display: flex;
          justify-content: space-between;
          line-height: 1.5rem;
          margin-top: 0.6rem;

          span {
            text-decoration: underline;
            min-width: 0;
            color: #484848;
            font-size: 1.1rem;
          }

          .getscore-ele-btn {
            border-radius: 5px;
            padding: 0.4rem 0.8rem 0.4rem 0.8rem;
            border-style: none;
          }

          .getscore-ele-btn,
          .getscore-ele-btn:focus,
          .getscore-ele-btn.active,
          .getscore-ele-btn:active:focus {
            background-image: none;
            outline: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
          }

          .text-orange {
            color:#fd9620
          }

          .text-red {
            color:#f84d4d
          }

          .score {
            display: inline-block;
            max-width: 60%;
            font-size: 1rem;
            text-align: justify;
            text-decoration: none;
          }
        }
      }
    }
  }

  .address-action {
    width: 100%;
    text-align: center;
    position: relative;
    margin-top: 1rem;

    img {
      cursor: pointer;
    }

    .seeall-ele-btn {
      position: absolute;
      background-color: #5ae5b3;
      border-radius: 5px;
      padding: 0.4rem 0.8rem 0.4rem 0.8rem;
      border-style: none;
      right: 1.5rem;
      font-size: 1.3rem;
      height: 46px;
      top: calc(50% - 23px);
      font-family: "Lato", sans-serif;
    }
  }
}

@media screen and (max-width: 1024px) {
  .address-action {
    display: flex;
    flex-direction: column;

    img {
      align-self: center;
    }

    .seeall-ele-btn {
      position: relative !important;
      right: 0;
    }
  }
}

@media screen and (max-width: 1759px) {
  .card-content .address {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;

    button {
      margin: 3px 3px;
      width: 80%;
    }

    * {
      font-size: 0.9rem !important;
    }
  }

  /*Coin Template CSS End*/
}
