.section-back-office {
  /*Comapany-List Start*/
  .company-list-main {
    .company-list {
      button {
        font-size: 14px;
        min-width: 130px;
        border-radius: 40px;
        border: none;
        background: #1c2253;
        color: #ffffff;
        padding: 5px 60px;
        outline: none;
        white-space: nowrap;

        &:focus {
          box-shadow: none;
        }
      }

      .active {
        background-color: rgb(253, 150, 32);
        color: white;
      }
    }
  }

  /*Comapany-List End*/

  .tabination {

    .datePicker {
      padding: 10px;
      border-radius: 40px;
      outline: none;
      border: none;
      margin-bottom: 10px;
    }

    .filter-btn {
      color: #ffffff;
      padding: 10px 40px;
      border-radius: 40px;
      box-shadow: none;
    }

    ul {
      display: block;
      white-space: nowrap;
      overflow: auto;
      li {
        display: inline-block;
        margin: 0 10px;

        button {
          color: #ffffff;
          border: 2px solid #ffffff;
          padding: 10px 40px;
          border-radius: 40px;
          outline: none;
          background: transparent;
        }
      }

      .active {
        border-radius: 40px;
        border: 2px solid #2367c8;
        background: #2367c8;
        color: #ffffff;
        padding: 10px 40px;
        outline: none;
      }
    }

    .user-btn {
      padding: 10px 30px;
      border-radius: 20px;
      border: none;
      outline: none;
      color: #ffffff;
      background: #fd9620;
    }
  }


  .headTab::-webkit-scrollbar {
    width: 10px !important;
  }

  /* Track */
  .headTab::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey !important;
    border-radius: 10px !important;
  }

  /* Handle */
  .headTab::-webkit-scrollbar-thumb {
    background: #384378 !important;
    border-radius: 10px !important;
  }

  .wrap-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }

  .add-crd-btn {
    font-size: 14px;
    border-radius: 40px;
    border: none;
    box-shadow: none;
    outline: none;
  }
}

/*Address Add In Monitoring Pop up*/

.back-select {
  color: #fff;
  display: inline-flex;

  .css-1bl8mcx-Control, .css-1domaf0 {
    display: flex;
    background: #fd9620;
    height: 55px;
    align-items: center;
    border-radius: 10px;
  }

  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  .css-1okebmr-indicatorSeparator {
    background-color: #fff;
  }
}

/*Super Admin Search Field*/
.super-admin-search {
  border: 2px solid #fff;
  border-radius: 40px;
  color: #FFFFFF;
  box-shadow: none;
  outline: none;
  padding: 22px;
  width: 70% !important;

  &:focus {
    border: 2px solid #fff;
    background: #1c2253;
    outline: none;
    color: #FFFFFF;
  }
}
.super-admin-limit {
  border: 2px solid #fff;

  color: #FFFFFF;
  box-shadow: none;
  outline: none;
  padding: 22px;
  width: 100% !important;

  &:focus {
    border: 2px solid #fff;
    background: #1c2253;
    outline: none;
    color: #FFFFFF;
  }
}

.monitoring-table {
  table {
    width: 100%;
    border-collapse: separate;
    border-radius: 1em;
    overflow: hidden;
    border-spacing: 0 15px;
    vertical-align: top;
    align-items: start;
  }

  tr {
    padding: 1.5em;
    background: #1c2253;
    border-radius: 1em 0 0 1em;
  }

  thead {
    tr {
      background: #384378;
      border-radius: 1em 0 0 1em;
    }

    td {
      font-size: 16px;
      font-family: Rubik-medium;
      padding: 1.5em;
    }
  }

  tbody {
    td {
      vertical-align: top;
      align-items: start;
      padding: 15px 1.5em;
    }

    td:nth-child(2) {
      overflow-x: hidden;

      li {
        overflow: hidden;
        max-width: 250px;
        text-overflow: ellipsis;
      }
    }
  }

  td {
    font-size: 14px;
    white-space: nowrap;
    border: transparent;
  }

  td:first-child {
    border-radius: 1em 0 0 1em;
  }

  td:last-child {
    border-radius: 0 1em 1em 0;
  }


}

.monitoring-table::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.monitoring-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.monitoring-table::-webkit-scrollbar-thumb {
  background: #384378;
  border-radius: 10px;
}