@font-face {
	font-family: 'ChampagneLimo-regular';
	src: url('ChampagneLimousines.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'ChampagneLimo-Bold';
	src: url('ChampagneLimousines-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'ChampagneLimo-Bold-Italic';
	src: url('ChampagneLimousines-Bold-Italic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'ChampagneLimo-Italic';
	src: url('ChampagneLimousines-Italic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}
