/*
*Style For pagination
*/

.paginationStyle {
  a {
    color: #FFFFFF;
    background-color: transparent;
    box-shadow: none;

    &:hover {
      border: 1px solid transparent;
      background-color: #fd9620;
      color: white;
    }
  }
}

.active-page-link {
  background-color: #fd9620 !important;
  border: 1px solid transparent;
}