.sidebar-wraper {
    background-color: white;
    width: 90px;

    .sidebar {
        width: 45px;
        padding-top: 33.2px;

        .sidebar-menu {
            list-style: none;
            max-width: 70px;

            li {
                cursor: pointer;

                &.burger {
                    margin-bottom: 210px
                }

                &:not(.burger) {
                    &.active {
                        border-left: 5px solid $primary-orange;
                    }
                }

                margin-bottom: 57px;
                text-align: right;

                .main-nav-icon {
                    width: 28px;
                    height: 28px;
                    fill: #828282;
                }

                &.active {
                    .main-nav-icon {
                        fill: $primary-orange;
                    }
                }
            }
        }
    }
}
