$selected: #5ae5b3;
$apibtn: #4794fd;
$creditsbtn: #ffc000;
$margin-button: 12px;
$button-width-provider-left: 260px;
$button-width-provider-right: 171px;
$button-height: 51px;
$coin-color: #ff8a00;
$breakpoint-1: 768px;
$breakpoint-2: 1024;
$fontSize-search: 20px;
$fontSize-search-min: 20px;

@mixin center-flex {
  display: flex;
  text-align: center;
  justify-content: center;
}

.search-query-container {
  font-family: 'Lato', sans-serif;
  font-size: $fontSize-search;
  background: #f4f4f4;

  .reo-click {
    border: 2px solid $selected !important;
    background-color: $selected;
    color: white;
  }

  .reorder-btn {
    width: 137px;
    border: 1px solid #484848;
    border-radius: 8px;
    padding: 1px 9px;
    font-size: $fontSize-search +2px;
    color: #484848 !important;

    &:hover {
      cursor: pointer;
    }

    img {
      position: relative;
      right: -9px;
      top: -1px;
      max-height: 30px;
      max-width: 30px;
    }
  }

  .provider-item {

    max-width: 477px;
    min-width: 370px;
    margin: 48px;

    .cursor-move {
      cursor: grab;
    }

    h6 {
      font-family: Lato;
      font-weight: bold;
      font-size: $fontSize-search;
    }

    .card-content {
      font-family: "Helvetica Neue" !important;
      border-radius: 6px;
      padding: 19px;
      background: white;
    }

    img {
      margin-bottom: 11px;
    }

    button {
      width: 100%;
      font-size: $fontSize-search;
      border: none;
      height: $button-height;

      img {
        margin: 0;
      }
    }

    .button-item-group {
      font-family: Lato;
      @include center-flex();

      .button-provider-left-wraper {
        padding-right: .85rem !important;
      }

      .button-provider-left {
        padding: 5px !important;
        background-color: $selected;
        margin-right: $margin-button;

        .button-content {
          width: 65%;
          text-align: left;

          span {
            margin-left: 17.5%
          }
        }
      }

      .button-provider-right-credit {
        background-color: $creditsbtn;
        padding: 5px !important;
        // max-width: $button-width-provider-right;
        // width: $button-width-provider-right;
      }

      .button-provider-right-api {
        background-color: $apibtn;
        padding: 5px !important;

        // width: $button-width-provider-right;
        // min-width: $button-width-provider-right/2;
      }
    }
  }

  .selected-border {
    border: 3px solid $selected !important;
  }

  .unselected-border {
    border: 3px solid transparent !important;
  }

  .before-address {
    &:before {
      content: "Address";
    }
  }

  .search-box-coin {
    .search-box {
      @include center-flex();
    }

    .search-bottom-box {
      @include center-flex();
      justify-content: flex-end;

      span {
        margin: 5px;
      }
    }

    .switch {
      background: $coin-color;
    }

    img {
      max-width: 28px;
      max-height: 28px;
      margin-top: auto;
    }

    input {
      min-width: 480px;
      height: 100%;
    }

    h3 {
      font-size: calc(#{$fontSize-search} + 7px);
      font-weight: bold;
    }

    .dropdown-coin {
      height: 100%;

      button {
        background: #ff8a00;
        color: white;
        width: 122px;
      }
    }

    .button-search {
      font-size: $fontSize-search;
      border: none;
      height: 55px;
      width: 172px;
      background: $selected;
    }

    .bottom-search {
      padding-top: 10px;
    }
  }
}

@media (max-width: 822px) {
  .react-switch-bg {
    max-width: 44px;
    max-height: 15px;
  }

  .react-switch-handle {
    max-width: 13px;
    max-height: 13px;
  }

  .button-provider-left {
    img {
      max-height: 25px;
      max-width: 25px;
    }

    span {
      span {
        height: 25px;
      }

    }
  }

  .button-search {
    width: 100% !important;
  }

  .provider-item {
    button {
      font-size: $fontSize-search-min !important;
    }
  }

  .search-query-container,
  .proider-item button {
    font-size: $fontSize-search-min !important
  }

  .search-box-coin {
    h3 {
      font-size: calc(#{$fontSize-search-min} + 15px) !important;

    }
  }

  .search-box {
    flex-direction: column;
    width: 100%;

    * {
      margin-bottom: 5px;
      width: 100% !important;
      min-height: 50px;
      min-width: 100% !important;
    }
  }

  div {
    margin-bottom: 10px;
  }
}

@media (max-width: 1344px) {
  .provider-item {
    margin: 22px !important;
  }

  .button-item-group {
    button {
      margin-top: 5px;
    }
  }

  .search-box {
    flex-direction: column;
    width: 100%;

    * {
      margin-bottom: 5px;
      width: 100% !important;
      min-height: 50px;
      min-width: 100% !important;

    }
  }

  .search-query-container .proider-item button {
    // font-size: $fontSize-search-min !important
  }
}

@media (max-width: 721px) {
  .button-item-group {
    @include center-flex();
    flex-direction: column !important;

  }
}

// @media (min-width: 1200px) and (max-width: 1581px) {
//   .provider-item {
//     max-width: 100% !important;
//   }
// }
@media (min-width: 1200px) and (max-width: 1904px) {
  .provider-item {
    max-width: 100% !important;
  }

  .row-respon {
    justify-content: center !important;
  }
}

//Bootstrap custom
.search-query-container .provider-item {
  max-width: 477px;
  min-width: 370px;
  margin: 20px;
}


.hyperlink-icon {
  vertical-align: middle;
  font-size: 12px;
  width: 20px;
}
