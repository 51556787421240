.home-container {
  font-family: 'Lato', sans-serif;
  background-color: #F4F4F4;
  width: 100%;
  min-height: 100%;
  padding: 30px 80px 0 80px;

  span.logo {
    font-family: 'ChampagneLimo-bold';
    color: #FF8A00;
  }

  .reorder-btn-container,
  .add-widget-btn-container {
    display: flex;
    justify-content: flex-end;

    button {
      border-radius: 5px;
      border: 1px solid #484848;
      color: #484848;
      outline: none;
      display: flex;
      align-items: center;
      height: 24px;
      cursor: pointer;
    }
  }

  .reorder-btn-container {
    & > button {
      padding: 0 10px;

      & > img {
        margin-left: 5px;
        width: 20px;
      }
    }
  }

  .add-widget-btn-container {
    margin-bottom: 50px;

    & > button {
      padding: 0 20px;
    }
  }

  h1 {
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;

    strong {
      font-weight: 800;
    }

    span {
      letter-spacing: 0.05em;
      font-family: 'ChampagneLimo-bold';
    }
  }

  .widget {
    background-color: white;
    box-shadow: 0 10px 20px rgba(112, 112, 112, 0.19), 0 6px 6px rgba(112, 112, 112, 0.23);
  }

  .main-content {
    display: flex;
    margin: 15px 0;

    & > * {
      flex-grow: 1;
    }

    .store-and-events {
      display: flex;
      flex-direction: column;
      margin: 0 20px;
      width: 30%;
    }

    .news-widget-container {
      width: 35%;
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 200px;
      padding: 0 30px;
      height: 100%;

      h3 {
        text-align: center;
        margin: 20px 0 20px 0;
        font-size: 21px;
        font-family: Lato;
        font-weight: bold;

        span {
          color: #4794FD;
        }
      }

      .arrow-down {
        margin-bottom: 30px;
        margin-top: auto;
        text-align: center;
        cursor: pointer;
      }

      .new-list {
        margin-bottom: 30px;

        .new-item {
          display: flex;
          height: 6rem;
          overflow: hidden;
          margin: 15px 0;

          .new-thumbnail {
            margin-right: 10px;
            width: 30%;
            background-color: gray;
            position: relative;

            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
            }

            .new-category {
              position: absolute;
              bottom: 5px;
              right: 5px;
              background-color: #FFCD2F;
              padding: 2px 4px;
              border-radius: 4px;
              font-size: 0.7rem;
            }

          }

          .new-detail {
            width: 70%;
            font-size: 0.7rem;

            a {
              color: black;
            }

            *:nth-child(2) {
              opacity: 0.5;
              margin: 5px 0;
            }

            *:nth-child(3) {
              max-height: 2.2rem;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: justify;
            }

          }

        }
      }

    }

    .store-widget-container {
      position: relative;

      overflow: auto;
      padding: 0 50px;

      h2 {
        text-align: center;
        margin: 15px 0 20px 0;
        font-size: 1.9rem;

        strong {
          font-family: Lato;
          font-size: 24px;
        }
      }

      img[alt="new symbol"] {
        position: absolute;
        top: 15px;
        right: 20px;
        width: 45px;
      }

    ;

      .widget-content {
        margin-bottom: 20px;

        &__title {
          text-align: center;
          font-size: 1.3rem;
        }

        &__data {
          margin-top: 10px;
          text-align: center;
        }

        &__actions {
          text-align: center;
          margin-bottom: 30px;

          button {
            height: 3rem;
            margin-right: 20px;
            margin-top: 0;
            width: 8rem;
            font-size: 1rem;
            outline: none;
            border-radius: 5px;
            border: none;
            color: white;
          }

          .add-btn {
            background: #5AE5B3;

            img {
              width: 1.5rem;
              margin-right: 20px;
            }
          }

          .buy-btn {
            background-color: #FFC000;
          }
        }
      }
    }

    .event-widget-container {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0 20px;

      h3 {
        text-align: center;
        margin: 25px 0 35px 0;
        font-size: 21px;
        font-family: Lato;
        font-weight: bold;

        span {
          color: #FF5242;
        }
      }

      .arrow-down {
        margin-bottom: 30px;
        margin-top: auto;
        text-align: center;
        cursor: pointer;
      }

      .event-list {
        margin-bottom: 30px;

        .event-item {
          margin: 15px 0;
          display: flex;
          padding-bottom: 15px;
          position: relative;
          align-items: center;

          &:not(:last-child)::after {
            content: "";
            position: absolute;
            background-color: #707070;
            bottom: 0;
            height: 1px;
            width: 50%;
            left: 25%;
          }

          .event-detail {
            width: 70%;

            .client-name {
              font-weight: bold;
            }

            .client-action,
            .client-action-time {
              padding-left: 10px;
            }

            .client-action-time {
              opacity: 0.5;
              font-style: italic;
            }
          }

          .navigate-icon {
            width: 30%;
            text-align: center;;

            img {
              width: 3rem;
              cursor: pointer;
            }
          }
        }
      }
    }

    .market-widget-container {
      position: relative;
      width: 35%;
      padding: 0 30px;
      height: 100%;

      h3 {
        text-align: center;
        margin: 20px 0 35px 0;
        font-size: 21px;
        font-family: Lato;
        font-weight: bold;

        span {
          color: #5AE5B3;
        }
      }

      img[class="live-icon"] {
        position: absolute;
        right: 30px;
        top: 25px;
      }

      .market-data {

        .market-item {
          display: flex;
          border-bottom: 1px solid rgba(166, 161, 161, .5);
          padding: 12px 0;
          font-size: 0.8rem;

          .index {
            width: 6%;
            font-weight: 600;
          }

          .name {
            width: 25%;
            font-weight: 900;
          }

          .market-cap,
          .price,
          .volume-24h {
            width: 23%;
            text-align: right;
            font-weight: 600;
          }

          .price,
          .volume-24h {
            color: #4794FD;
          }

          img {
            width: 20px;
            height: 20px;
            align-self: center;
            margin-right: 5px;
          }

          &:last-child {
            margin-bottom: 20px;
          }
        }

      }
    }
  }

  .carousel-container {
    width: 100%;
    margin-bottom: 20px;

    .navigation-dots {
      display: flex;
      justify-content: center;

      .navigation-dot {
        cursor: pointer;

        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .home-container {
    padding: 30px 20px 0 20px;

    h1 {
      font-size: 2rem;
    }

    .main-content {

      .news-widget-container {
        padding: 0 15px;

        h3 {
          font-size: 1.3rem;
          margin: 15px 0 15px 0;
        }
      }

      .store-widget-container {
        padding: 0 20px;

        h2 {
          font-size: 1.5rem;
          margin: 10px 0 15px 0;
        }

        img[alt="new symbol"] {
          top: 10px;
          width: 35px;
        }

      ;

        .widget-content {
          &__title {
            font-size: 1.2rem;
          }

          &__data {
            font-size: 1rem;
          }

          &__actions {
            button {
              width: 7rem;
              height: 2rem;
              margin-right: 10px;
            }
          }

        }


      }

      .event-widget-container {
        flex-grow: 2;

        h3 {
          font-size: 1.3rem;
          margin: 15px 0 10px 0;
        }

        .event-list {
          font-size: .9rem;
          margin-bottom: 20px;

          .event-item {
            .navigate-icon {
              img {
                width: 2.5rem;
              }
            }
          }
        }
      }

      .market-widget-container {
        padding: 0 10px;

        h3 {
          font-size: 1.3rem;
          margin: 10px 0 15px 0;
        }

        img[class="live-icon"] {
          top: 16px;
          right: 15px;
        }

        .market-data {
          .market-item {
            font-size: 0.6rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1367px) and (max-width: 1666px) {
  .home-container {
    padding: 40px 25px 0 25px;

    h1 {
      font-size: 2.2rem;
    }

    .main-content {

      .news-widget-container {
        padding: 0 20px;

        h3 {
          font-size: 1.5rem;
          margin: 20px 0;
        }
      }

      .store-widget-container {
        padding: 0 30px;

        h2 {
          font-size: 1.7rem;
          margin: 10px 0 15px 0;
        }

        img[alt="new symbol"] {
          top: 10px;
          width: 40px;
        }

      ;

        .widget-content {
          &__title {
            font-size: 1.3rem;
          }

          &__data {
            font-size: 1rem;
          }

          &__actions {
            button {
              width: 8rem;
              height: 2.3rem;
              margin-right: 15px;
            }
          }

        }


      }

      .event-widget-container {
        h3 {
          font-size: 1.5rem;
          margin: 25px 0 20px 0;
        }

        .event-list {
          font-size: 1rem;
          margin-bottom: 20px;

          .event-item {
            .navigate-icon {
              img {
                width: 2.7rem;
              }
            }
          }
        }
      }

      .market-widget-container {
        padding: 0 15px;

        h3 {
          font-size: 1.5rem;
          margin: 20px 0 25px 0;
        }

        img {
          top: 28px;
          right: 20px;
        }

        .market-data {
          .market-item {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
