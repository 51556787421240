span.synapse-logo {
    font-family: 'ChampagneLimo-bold';
    font-size: 41px;
    margin-left: 20px;
    color: $primary-orange;
}

.notification {
    display: grid;
    align-items: center;
    margin: 0 10px;
}

.nav-account {
    border-radius: 50%;
    cursor: pointer;
}

ul.main-nav {
    list-style: none;
    margin: 0;
    left: 50%;
    position: absolute;
    height: 100%;
    transform: translateX(-50%);

    li a {

        padding: 0 !important;
        height: 100%;
        display: grid;
        align-content: center;
        min-width: 100px;
        text-align: center;
        border-radius: 0;
        border: none;
        color: black;
        border-top: 5px solid white;
        margin: 0 5rem;

        &:hover {
            background-color: white;
        }

        &.active {
            border-top: 5px solid #FF8A00;
        }
    }
}

img.navbar-brand-logo {
    width: 31px;
    margin-left: 1rem;
    transform: rotate(11deg);
}